import { FC, useEffect, useState, KeyboardEvent, useRef } from "react";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { AutoComplete, Button, Input, theme, Tooltip, Typography, InputRef } from "antd";
import { useNavigate } from "react-router-dom";

import { useOrganizationStore, useSearchArtifactStore, useSearchStore } from "store";
import { AIBasedSearchQueryPolicyName, AISearchQueryEngine, DefaultSearchQueryEnginePolicyName, NativeSearchQueryEngine, PolicyCategoryType, SearchBarProps, SearchMetaData, SearchSuggestion } from "types";
import { validateSearchQuery } from "utility/search";
import { getSearchSuggestion } from "utility/search/assist";
import { usePolicyStore } from "store/policy";

import { calculateSearchQueryOnSelect } from "./utils";
import { SearchFilters } from "./SearchFilters";
import { SearchFilterButton } from "./SearchFilterButton";
import styles from "./SearchBar.module.scss";


const SearchBar: FC<SearchBarProps> = ({ inHeader, onQuery }) => {
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [searchButtonStatus, setSearchButtonStatus] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [searchOptions, setSearchOptions] = useState<any[]>([]);
  const [searchFilterOptions, setSearchFilterOptions] = useState<any[]>([]);
  const [lastTokens, setLastTokens] = useState<string[]>([]);
  const [suggestionBoxOpen, setSuggestionBoxOpen] = useState(false);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const { context } = useOrganizationStore((state) => ({ context: state.context }));
  const [isAiQueryEngineEnabled, setAiQueryEngineEnabled] = useState<boolean>(false);
  const [defaultSearchQueryEngine, setDefaultSearchQueryEngine] = useState<string>(NativeSearchQueryEngine);


  const { searchPolicies, getPolicies } = usePolicyStore((state) => ({
    searchPolicies: state.policies[PolicyCategoryType.SearchGeneral],
    getPolicies: state.getPolicies
  }));


  const { artifacts, getArtifacts } = useSearchArtifactStore((state) => ({
    artifacts: state.artifacts,
    getArtifacts: state.getArtifacts,
  }));

  const { searchMetaData, setSearchMetaData, runSearchX, runSearch, resetSearchState } = useSearchStore(
    (state) => ({
      searchMetaData: state.searchMetaData,
      setSearchMetaData: state.setSearchMetaData,
      runSearchX: state.runSearchX,
      runSearch: state.runSearch,
      resetSearchState: state.resetSearchState,
    })
  );

  const syncArtifacts = async () => {
    try {
      if (!artifacts.length) {
        await getArtifacts();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const asyncUseEffect = async () => {
      syncArtifacts();
    };
    asyncUseEffect();
    if (searchMetaData && searchMetaData.query && searchMetaData.query != "") {
      validateSearch();
    }
  }, []);

  useEffect(() => {
    if (searchPolicies) {

      const aiEnabledPolicy = searchPolicies.find((p) => p.name == AIBasedSearchQueryPolicyName);
      if (aiEnabledPolicy?.value.data) {
        setAiQueryEngineEnabled(aiEnabledPolicy?.value.data);
      }
      const defaultSearchQueryEnginePolicy = searchPolicies.find((p) => p.name == DefaultSearchQueryEnginePolicyName);
      if (defaultSearchQueryEnginePolicy?.value.data) {
        setDefaultSearchQueryEngine(defaultSearchQueryEnginePolicy?.value.data);
        if (searchMetaData.isAiBasedQuery == undefined) {
          if (defaultSearchQueryEnginePolicy?.value.data == AISearchQueryEngine) {
            searchMetaData.isAiBasedQuery = true
            setSearchMetaData({ ...searchMetaData, isAiBasedQuery: true })
          } else {
            searchMetaData.isAiBasedQuery = false
            setSearchMetaData({ ...searchMetaData, isAiBasedQuery: false })
          }
        }
      }
    }

  }, [searchPolicies]);

  useEffect(() => {
    onSearch(searchMetaData.query);
  }, [searchMetaData.query]);

  useEffect(() => {
    resetSearchState(false);
  }, [context]);

  const resetState = (engine: string) => {
    setSearchButtonStatus(false);
    setSearchOptions([])
    setLastTokens([]);
    setSuggestionBoxOpen(false);
    setSearchInProgress(false);
    if (engine == NativeSearchQueryEngine) {
      populateSearchOptions("");
    }
  };

  //populateSearchOptions generate searchOptions based on the query
  function populateSearchOptions(value: string) {
    if (searchMetaData.isAiBasedQuery) {
      setSearchOptions([])
      setLastTokens([]);
    } else {
      const searchSuggestion = getSearchSuggestion(value);
      const searchSuggestionOptions = searchSuggestion.querySuggestions.map((querySuggestion) =>
        querySuggestion.tokens.map((token) => {
          const isMandatory = querySuggestion.mandatoryTokens?.find((x) => x == token);
          return {
            label: isMandatory ? token + "*" : token,
            value: token,
          };
        })
      );
      setSearchOptions(searchSuggestionOptions[0])
      setLastTokens(searchSuggestion.lastTokens);
    }
    if (value != searchMetaData.query) {
      setSearchMetaData({ ...searchMetaData, id: undefined, query: value, nativeQuery: "", assistMessage: "" } as SearchMetaData);
    } else {
      setSearchMetaData({ ...searchMetaData, query: value } as SearchMetaData);
    }
  }

  function populateSearchFilterOptions() {
    setSearchFilterOptions([{ label: "dummy", value: "dummy", }]);
  }

  function onSearch(value: string) {
    setSearchButtonStatus(false);
    populateSearchOptions(value);
    value != "" && validateSearch();
  }

  const onSelect = (value: string) => {
    setSearchButtonStatus(false);
    onSearch(calculateSearchQueryOnSelect(value, searchMetaData.query, lastTokens));
    setSearchButtonStatus(true);
  };

  const onExecuteSearch = async () => {
    try {
      if (searchMetaData.query) {
        if (onQuery) onQuery(searchMetaData);
        else {
          setLoader(true);
          const searchRunId = searchMetaData.id
            ? await runSearch()
            : await runSearchX();
          if (searchRunId) {
            resetSearchState(true);
            navigate(`/search-runs/${searchRunId}`);
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
      setSearchInProgress(false);
    }
  };

  const validateSearch = () => {
    if (searchMetaData.query != "") {
      if (searchMetaData.isAiBasedQuery) {
        setSearchButtonStatus(true);
        return
      }
      let result = false;
      try {
        if (searchMetaData.query) {
          result = validateSearchQuery(searchMetaData.query);
        }
      } catch (error) {
        result = false;
      }
      result ? setSearchButtonStatus(true) : setSearchButtonStatus(false);
    }
  };

  const renderSearchButton = () => (
    <Button
      style={{
        backgroundColor: token.colorPrimaryActive,
      }}
      loading={loader}
      icon={<SearchOutlined style={{ color: token.colorTextLightSolid }} />}
      onClick={() => {
        if (!searchInProgress && searchButtonStatus) {
          setSearchInProgress(true);
          onExecuteSearch();
        }
      }}
    />
  );

  const renderInputSuffix = () => {
    if (searchMetaData.query != "" && !searchMetaData.isAiBasedQuery) {
      return searchButtonStatus ? (
        <CheckCircleFilled style={{ color: token.colorSuccess }} />
      ) : (
        <CloseCircleFilled style={{ color: token.colorWarning }} />
      );
    } else {
      return <></>;
    }
  };
  const onEngineChanged = (engine: string) => {
    resetState(engine);
    setSearchMetaData({
      ...searchMetaData,
      id: undefined,
      query: "",
      name: "",
      nativeQuery: "",
      assistMessage: "",
      isAiBasedQuery: engine == AISearchQueryEngine ? true : false
    } as SearchMetaData)
  }

  const renderInputPrefix = (isAiBasedQuery?: boolean) => {
    return (
      <SearchFilterButton
        isAiBasedQuery={(isAiBasedQuery && isAiBasedQuery == true) ? true : false}
        onClick={() => setFilterVisible(!filterVisible)}
        onEngineChanged={(engine) => onEngineChanged(engine)}
      />
    )
  };

  const renderSearchFilters = () => {
    return (
      <SearchFilters
        isAiQueryEngineEnabled={isAiQueryEngineEnabled}
        isAiBasedQuery={searchMetaData.isAiBasedQuery}
        searchTags={searchMetaData.searchTags}
        searchProviders={searchMetaData.searchProviders}
        onEngineChanged={(engine) => onEngineChanged(engine)}
        onTagsChanged={(selectedTags) => {
          setSearchMetaData({
            ...searchMetaData,
            id: selectedTags != searchMetaData.searchTags ? undefined : searchMetaData.id,
            searchTags: selectedTags,
          })
        }}
      />
    )
  }


  const handleKeyDownAutoComplete = (e: KeyboardEvent<HTMLDivElement>) => {
    switch (e.key) {
      case "Escape": {
        e.preventDefault();
        e.stopPropagation();
        setFilterVisible(false);
        break;
      }
      case "Enter": {
        e.preventDefault();
        e.stopPropagation();
        break;
      }
    }
  };

  return (
    <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <AutoComplete
        style={{ flex: 1 }}
        autoFocus={inHeader ? false : filterVisible ? false : true}
        defaultOpen={false}
        options={filterVisible ? searchFilterOptions : searchOptions}
        disabled={searchInProgress}
        onSelect={filterVisible ? undefined : onSelect}
        onSearch={filterVisible ? undefined : onSearch}
        onFocus={() => {
          populateSearchOptions(searchMetaData.query);
          populateSearchFilterOptions();
        }}
        value={searchMetaData.query}
        onDropdownVisibleChange={(open: boolean) => {
          setSuggestionBoxOpen(open);
          setFilterVisible(false);
        }}
        onKeyDown={handleKeyDownAutoComplete}
        dropdownRender={filterVisible ? (menus) => renderSearchFilters() : undefined}
      >
        <Input.Search
          placeholder="Search for any artifact across HyprEdge and connected Apps"
          loading={loader}
          enterButton={renderSearchButton()}
          onPressEnter={() => {
            if (!searchInProgress && !suggestionBoxOpen && searchButtonStatus) {
              setSearchInProgress(true);
              onExecuteSearch();
            }
          }}
          onSearch={() => {
            if (!searchInProgress && !suggestionBoxOpen && searchButtonStatus) {
              setSearchInProgress(true);
              onExecuteSearch();
            }
          }}
          prefix={renderInputPrefix(searchMetaData.isAiBasedQuery)}
          suffix={renderInputSuffix()}
          size="middle"
        />
      </AutoComplete>

    </div>
  );

};

export default SearchBar;
