
import { addWidgetApi, createDashboardApi, deleteDashboardApi, deleteWidgetApi, listDashboardsApi, updateDashboardPropertiesApi, updateWidgetApi, WidgetDto, type DashboardDto, type WidgetCreateRequestDto, type WidgetUpdateRequestDto } from "api";
import { transferWidgetApi, updateDashboardWidgetPositionsApi } from "api/dashboard";
import { Layout } from "react-grid-layout";
import { cols, Dashboard, DashboardStore, Widget, WidgetCreateRequest, WidgetType, WidgetUpdateRequest } from "types";
import { generateLayout } from "utility";
import { create } from "zustand";

const widgetFromDto = (widget: WidgetDto): Widget => {
  return {
    id: widget.id,
    type: widget.type as WidgetType,
    unit: widget.unit,
    title: widget.title,
    description: widget.description,
    search: widget.search,
    color: widget.color,
    position: widget.position
  };
}

const dashboardFromDto = (dashboard: DashboardDto): Dashboard => { 
  const widgets = dashboard.widgets.map(widgetFromDto);
  return {
    id : dashboard.id,
    name: dashboard.name,
    description: dashboard.description,
    color: dashboard.color,
    widgets,
    layouts: {
      lg: generateLayout(widgets, cols.lg),
      md: generateLayout(widgets, cols.md),
      sm: generateLayout(widgets, cols.sm),
      xs: generateLayout(widgets, cols.xs),
      xxs: generateLayout(widgets, cols.xxs)
    }
  };
}


export const useDashboardStore = create<DashboardStore>(
  (set, get) => ({
    dashboards: {},    
    dashboardEdit : null,
    setDashboardEdit: (dashboardId: string | null) => {
      set((state) => ({...state, dashboardEdit: dashboardId}));
    },
    getDashboards: async () => {
      const response = await listDashboardsApi();
      const dashboards = response.data.dashboards.reduce((acc, dashboard) => {
        acc[dashboard.id] = dashboardFromDto(dashboard);
        return acc;
      }, {} as Record<string, Dashboard>);
      set((state) => ({...state, dashboards}));
    },
    
    createDashboard: async (name: string, description: string, color: string) => {
      await createDashboardApi(name, description, color);
      await get().getDashboards();    
    },

    setLayout: async (dashboardId: string, layouts : Layout[] ) => {      
      const sorted = layouts.slice().sort((a, b) => { if (a.y === b.y) return a.x - b.x; return a.y - b.y; });
      const positions : {[k: string] : number } = sorted.reduce((acc, item, index) => {
        acc[item.i] = index;
        return acc;
      }, {} as {[k: string] : number });
      await updateDashboardWidgetPositionsApi(dashboardId, positions);
      await get().getDashboards();
    },
    
    addWidget: async (dashboardId: string, widget : WidgetCreateRequest) : Promise<Widget>=> {
      const widgetDto : WidgetCreateRequestDto = {
        type: widget.type,
        unit: widget.unit,
        title: widget.title,
        description: widget.description,
        search: widget.search,
        color: widget.color,
        position: widget.position
      };
      const response = await addWidgetApi(dashboardId, widgetDto);
      await get().getDashboards();
      const dto: WidgetDto = response.data;
      return {
        id: dto.id,
        type: dto.type as WidgetType,
        unit: dto.unit,
        title: dto.title,
        description: dto.description,
        search: dto.search,
        color: dto.color,
        position: dto.position
      };
    },

    updateWidget: async (dashboardId: string, widgetId : string , widget: WidgetUpdateRequest) => {
      const widgetDto : WidgetUpdateRequestDto = {
        type: widget.type,
        unit: widget.unit,
        title: widget.title,
        description: widget.description,
        search: widget.search,
        color: widget.color,
        position: widget.position
      };
      const response = await updateWidgetApi(dashboardId, widgetId, widgetDto);
      await get().getDashboards();
      const dto : WidgetDto = response.data;
      return {
        id: dto.id,
        type: dto.type as WidgetType,
        unit: dto.unit,
        title: dto.title,
        description: dto.description,
        search: dto.search,
        color: dto.color,
        position: dto.position
      };
    },    
    
    
    deleteWidget: async (dashboardId: string, widgetId: string) => {
      await deleteWidgetApi(dashboardId, widgetId);
      await get().getDashboards();
    },  
    
    deleteDashboard: async (dashboardId: string) => {
      await deleteDashboardApi(dashboardId);
      await get().getDashboards();
    },
    
    updateDashboardProperties: async (dashboardId: string, name: string, description: string, color: string ) => {
      await updateDashboardPropertiesApi(dashboardId, name, description, color);
      await get().getDashboards();
    },

    transferWidget: async (sourceDashboardId: string, targetDashboardId: string, widgetId: string) => {
      await transferWidgetApi(sourceDashboardId, targetDashboardId, widgetId);
      await get().getDashboards();
    }
  })
);
