import { FC, useEffect, useState } from "react";
import {
  Collapse,
  Skeleton,
  Table,
  theme,
  Typography,
  Space,
  Avatar,
  Pagination,
  Button,
  Badge,
  Card,
  Popover,
  Image,
  Tooltip,
  List,
  Tag,
} from "antd";
import pRetry, {FailedAttemptError} from 'p-retry';
import { CodeBlock, atomOneDark, atomOneLight } from "react-code-blocks";
import { useMediaQuery } from "react-responsive";
import {  BigScreenQuery, SearchRunActionData, SearchRunActionDatas } from "types";
import { JsonViewer } from '@textea/json-viewer'
import {
  App,
  SearchRunResultMeta,
  SearchRunActionStatus,
  SearchRunAction,
  TextType,
  ArtifactFieldType,
  ProjectionRows,
} from "types";
import {
  useSearchStore,
  useEdgeGlobalStore,
  useAppStore,
  useSearchArtifactStore,
  useSettingsStore,
  useOrganizationStore,
} from "store";

import { getSearchResultsApi } from "api";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import RowActionResultDetail from "./rowActionResultDetail";
import RowActionResult from "./rowActionResult";
import { normalizeLanguageFromFileName } from "utility/search/normalize_language";
import { getAppLogoUrl } from "utility/app";
import { HLink } from "components/Link";
import { getTagColor } from "utility";


const { Panel } = Collapse;
const { Text, Link } = Typography;

const PAGE_SIZE = 15;

export interface ResultPanelProps {
  index: number;
  searchId: string;
  appSubscriptionId: string;
  artifactName: string;
  resultMeta: SearchRunResultMeta;
  editMode: boolean;
  tagId?: string;
}

const ResultPanel: FC<ResultPanelProps> = ({
  index,
  searchId,
  appSubscriptionId,
  artifactName,
  resultMeta,
  editMode,
  tagId,
}) => {
  const { token } = theme.useToken();
  const isBigScreen = useMediaQuery(BigScreenQuery);
  const { lightMode } = useSettingsStore((state) => ({
    lightMode: state.lightMode,
  }));
  const [results, setResults] = useState<any[]>([]);
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [app, setApp] = useState<App>({} as App);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [isPanelOpened, setIsPanelOpened] = useState(false);
  const [selectAllPageSelected, SetSelectAllPageSelected] = useState(false);
  const [allResultsSelected, setAllResultsSelected] = useState(false);
  const [needRowUpdates, setNeedRowUpdates] = useState(false);
  const [monitoringActions, setMonitoringActions] = useState<string[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const abortController = new AbortController();
  const {
    updateSelectedSearchResults,
    clearSelectedSearchResults,
    getSearchRunAction,
    getSearchRunActions,
    searchRunActions,
    searchRunActionSelectedDatas,
    showDetailedResults,
  } = useSearchStore((state) => ({
    updateSelectedSearchResults: state.updateSelectedSearchResults,
    clearSelectedSearchResults: state.clearSelectedSearchResults,
    getSearchRunAction: state.getSearchRunAction,
    getSearchRunActions: state.getSearchRunActions,
    searchRunActions: state.searchRunActions,
    searchRunActionSelectedDatas: state.searchRunActionSelectedDatas,
    showDetailedResults: state.showDetailedResults,
  }));

  const { artifacts, getArtifacts } = useSearchArtifactStore((state) => ({
    artifacts: state.artifacts,
    getArtifacts: state.getArtifacts,
  }));

  const {
    mspEnabled, 
    tags
  } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    tags: state.tags
  }));

  const getEdgeEndpoint = useEdgeGlobalStore((state) => state.getEdgeEndpoint);
  const { getApp } = useAppStore((state) => ({
    getApp: state.getApp,
  }));

  const selectAllRows = () => {
    const selectedList: number[] = [];
    results.map((i: any, index: number) => {
      selectedList.push(index);
    });
    setSelectedRowKeys(selectedList);
  };

  const getColumnRender = (value: any, textType?: TextType, language?: any, highlight?:any) => {
    if (!textType) {
      return String(value);
    }

    switch (textType) {
      case TextType.Artifact:
        return (
          <JsonViewer 
            rootName={false}
            editable={false}
            displayDataTypes={false}
            displaySize={false}
            enableClipboard={true}
            value={value}
            theme={lightMode ? "light" : "dark"}
            highlightUpdates={true}
            sx={{paddingLeft: 2}}
            defaultInspectDepth={0}
          />
        )
      case TextType.Url:
        return (
          <HLink
            ellipsis
            style={{ width: "200px" }}
            href={value}
            target="_blank"
            rel="noopener noreferrer"
            text={String(value)}
            tooltip={String(value)}
          />
        );
      case TextType.Map:
      case TextType.Array:
        if ("string" == typeof value) {
          value = value.split(",")
        }
        return value?.length > 0 && (
          <List
            dataSource={value}
            renderItem={(item :string) => (
              <List.Item >
                <Tooltip title={item}>
                  <Text style={{ width: isBigScreen ? "400px" : "200px" }} ellipsis>{item}</Text>
                </Tooltip>
              </List.Item>
            )}
          />
        );
      case TextType.Code: {
        let newValue = [...value].join("\n");
        const tabSpaces = "  ";
        const startNewLineRegEx = /^(\/n)/g;
        const endNewLineRegEx = /$\/n/g;
        newValue = newValue.replaceAll("\t", tabSpaces);
        newValue = newValue.replaceAll(startNewLineRegEx, "\n");
        newValue = newValue.replaceAll(endNewLineRegEx, "\n");
        return (
          <div style={{width: "auto"}}>
          <CodeBlock
            text={newValue}
            theme={lightMode ? atomOneLight :atomOneDark}
            language={language}
            showLineNumbers={false}
            wrapLongLines={false}
            as={undefined}
            forwardedAs={undefined}
            highlight={highlight}
          />
          </div>
        );
      }
      default:
        return (
          <Popover title={String(value)}>
            <Text ellipsis style={{ width: isBigScreen ? "400px" : "200px" }}>
              {String(value)}
            </Text>
          </Popover>
        );
    }
  };

  const fetchData = async (detailedView = false) => {
    try {
      setLoader(true);
      const edgeEndpoint = await getEdgeEndpoint(resultMeta.edgeID);
      const rows = await getSearchResultsApi(
        edgeEndpoint,
        resultMeta.tenantID,
        searchId,
        resultMeta.searchRunID,
        appSubscriptionId,
        artifactName,
        currentPage,
        PAGE_SIZE,
        detailedView
      );
      if(!rows.length) return;

      let artifact = artifacts.find((x) => x.name == artifactName);
      if (artifacts.length == 0) {
        const artifacts = await getArtifacts();
        artifact = artifacts.find((x) => x.name == artifactName);
      }
      
      const processedResponse = rows.map((i: any, index: number) => {
        if (artifact?.name == "Code") {
          let language = i["language"];
          const filename = i["filename"];
          if (!language && filename) {
            language = normalizeLanguageFromFileName(filename);
            if (language) {
              i["language"] = language;
            }
          }
        }
        return {
          ...i,
          key: (currentPage - 1) * PAGE_SIZE + index,
          actions: [],
        };
      });

      const d = rows[0];
      const columns = [];

      for (const key in d) {
        let displayName = key;
        if (artifact) {
          const field = artifact.fields[key];
          if (field) {
            displayName = field.displayName;
            columns.push({
              title: displayName,
              order: field.order,
              render: (record: any) =>
                getColumnRender(
                  record[key],
                  field.textType,
                  record["language"],
                  record["searchterm"]
                ),
            });
          }
        }
      }

      const sortedColumns: any[] = columns.sort(
        (column1, column2) => column1.order - column2.order
      );
      //action
      sortedColumns.push({
        title: "Actions",
        //fixed: 'right',
        render: (record: any) =>
          record.actions.length ? (
            <RowActionResult
              searchRunId={resultMeta.searchRunID}
              searchRunAction={record.actions[0]}
              rowIndex={record["key"]}
              showApp={showDetailedResults ? false : true}
            />
          ) : (
            <></>
          ),
      });
      setTableColumns(sortedColumns);
      setResults(processedResponse);
      allResultsSelected && selectAllRows();
      setNeedRowUpdates(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const onRowsSelectChange = (newSelectedRowKeys: React.Key[]) => {
    if (newSelectedRowKeys.length == 0) {
      setSelectedRowKeys([]);
      clearSelectedSearchResults(appSubscriptionId);
    } else {
      const newList = newSelectedRowKeys.map((i) => Number(i));
      const data = {
        searchRunID: resultMeta.searchRunID,
        tenantId: resultMeta.tenantID,
        isSelectAll: false,
        rowIndexes: newList,
      } as SearchRunActionData;
      setSelectedRowKeys(newList);
      updateSelectedSearchResults(appSubscriptionId, data);
    }
  };

  const onRowsSelectAll = (selected: boolean) => {
    if (!selected) {
      clearSelectedSearchResults(appSubscriptionId);
      SetSelectAllPageSelected(false);
    } else {
      SetSelectAllPageSelected(true);
      const data = {
        searchRunID: resultMeta.searchRunID,
        tenantId: resultMeta.tenantID,
        isSelectAll: true,
        rowIndexes: [],
      } as SearchRunActionData;
      updateSelectedSearchResults(appSubscriptionId, data);
      selectAllRows();
    }
  };

  const onSelectAllRows = () => {
    const data = {
      searchRunID: resultMeta.searchRunID,
      tenantId: resultMeta.tenantID,
      isSelectAll: true,
      rowIndexes: [],
    } as SearchRunActionData;
    updateSelectedSearchResults(appSubscriptionId, data);
    setAllResultsSelected(true);
    selectAllRows();
  };

  const onClearAllRows = () => {
    clearSelectedSearchResults(appSubscriptionId);
    setSelectedRowKeys([]);
    setAllResultsSelected(false);
    SetSelectAllPageSelected(false);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: onRowsSelectChange,
    onSelectAll: onRowsSelectAll,
  };

  const onPageChange = (page: number, pageSize: number) => {
    if (!allResultsSelected) {
      clearSelectedSearchResults(appSubscriptionId);
    }
    setCurrentPage(page);
  };

  useEffect(() => {
   if (isPanelOpened || index === 0) {
      fetchData(showDetailedResults);
    }
  }, [showDetailedResults, isPanelOpened, currentPage]);

  useEffect(() => {
    const asyncUseEffect = async () => {
      if (resultMeta.appID && resultMeta.appID != "") {
        try {
          const app = await getApp(resultMeta.appID);
          setApp(app);
        } catch (error) {
          console.log(error);
        }
      }
    };
    asyncUseEffect();
  }, [resultMeta]);

  useEffect(() => {
    Object.entries(searchRunActionSelectedDatas).length == 0 &&
      setSelectedRowKeys([]);
  }, [searchRunActionSelectedDatas]);

  useEffect(() => {
    return () => {abortController.abort("exiting search run result panel page")} ;
  }, []);

  const syncSearchRunActionInBackground = async (searchRunActionId: string) => {
    const sr = await getSearchRunAction(searchRunActionId, false);
    if (sr.status == SearchRunActionStatus.Running) {
      throw new Error(sr.status)
    }
  };

  useEffect(() => {
    const processedResponse = results.map((element, index) => {
      const actions = [];
      for (const sra of searchRunActions) {
        if(!sra.actionDatas) continue;
        const actionData = sra.actionDatas[appSubscriptionId];
        if (!actionData) continue;
        if (actionData.searchRunID != resultMeta.searchRunID) continue;
        const keyIndex = element["key"];
        if (actionData.isSelectAll) {
          actions.push(sra);
        } else {
          for (const rowIndex of actionData.rowIndexes) {
            if (rowIndex == keyIndex) {
              actions.push(sra);
              break;
            }
          }
        }
      }
      return { ...element, actions: actions };
    });
    setResults(processedResponse);
    setNeedRowUpdates(false);
  }, [searchRunActions, needRowUpdates]);

  useEffect(() => {
    for (const sra of searchRunActions) {
      if (
        sra.status == SearchRunActionStatus.Running &&
        !monitoringActions.find((x: string) => x == sra.id)
      ) {
        setMonitoringActions([...monitoringActions, sra.id]);
        pRetry(() => syncSearchRunActionInBackground(sra.id), {
          retries: 60,
          minTimeout: 5000,
          maxTimeout: 5000,
          signal: abortController.signal
        }).then(() => {
          setNeedRowUpdates(true);
        }).catch((e: FailedAttemptError) => {
          console.log("pretry sync search run status completed, exiting sync of status");
        })

      }
    }
  }, [searchRunActions]);

  const getRowActions = (record: any) => {
    const rowActions: SearchRunAction[] = [];
    record.actions &&
      record.actions.map((action: SearchRunAction) => {
        rowActions.push(action);
      });
    return rowActions;
  };

  return (
    <Collapse
      defaultActiveKey={index === 0 ? ["1"] : []}
      onChange={(e) => (e.includes("1") ? setIsPanelOpened(true) : null)}
      style={{
        backgroundColor: token.colorPrimaryBg,
      }}
    >
      <Panel
        header={
          <Space size={token.sizeMS}>
            {(app?.logoUrl || app?.darkLogoUrl) && (
              <Image
                preview={false}
                draggable={false}
                width={"90px"}
                height={"30px"}
                src={getAppLogoUrl(app, lightMode)}
              //alt="company-logo"
              />
            )}
            {app?.displayName && (
              <Text style={{ fontSize: token.fontSizeHeading5 }}>
                {`${app.displayName}(${resultMeta?.appSubscriptionName})`}
              </Text>
            )}
            {tagId && mspEnabled &&
              <Tag color={getTagColor(tagId)}>
                  {tags?.find((tag) => (tag.id == tagId))?.value}
              </Tag>
            }
            <Badge
              count={resultMeta.count}
              overflowCount={9999}
              showZero
              color={token.colorText}
            />
          </Space>
        }
        key="1"
      >
        {loader ? (
          <Skeleton active />
        ) : (
          <div style={{ width: "100%" }}>
            <Space
              size={token.size}
              direction="vertical"
              style={{ display: "flex" }}
            >
              {selectAllPageSelected && (
                <div
                  style={{
                    backgroundColor: token.colorBgTextActive,
                    textAlign: "center",
                  }}
                >
                  {allResultsSelected && (
                    <Text>{`All ${resultMeta.count} for this app are selected.`}</Text>
                  )}
                  {!allResultsSelected && 
                   (
                    <Text>{`All ${PAGE_SIZE > selectedRowKeys.length ? selectedRowKeys.length : PAGE_SIZE} results on this page are selected.`}</Text>
                  )}
                  {allResultsSelected && (
                    <Button
                      type="link"
                      onClick={onClearAllRows}
                    >{`Clear Selection`}</Button>
                  )}
                  {!allResultsSelected && (PAGE_SIZE < selectedRowKeys.length) && (
                    <Button
                      type="link"
                      onClick={onSelectAllRows}
                    >{`Select all ${resultMeta.count} results for this app`}</Button>
                  )}
                </div>
              )}
              <Table
                rowSelection={editMode ? rowSelection : undefined}
                columns={tableColumns}
                dataSource={results}
                scroll={{ x: true }}
                pagination={false}
                style={{ overflowX: "auto", scrollbarWidth: "inherit" }}
                expandable={{
                  expandedRowKeys: expandedRowKeys,
                  onExpand: (expanded, record) => {
                    setExpandedRowKeys(expanded ? [record.key] : []);
                  },
                  expandedRowRender: (record) =>
                    record.actions &&
                    record.actions.length && (
                      <Space
                        size={token.size}
                        direction="vertical"
                        style={{ display: "flex" }}
                      >
                        {getRowActions(record).map(
                          (action: SearchRunAction, index: number) => (
                            <RowActionResultDetail
                              key={action.id}
                              searchRunId={resultMeta.searchRunID}
                              searchRunAction={action}
                              rowIndex={record["key"]}
                              expanded={!index}
                            />
                          )
                        )}
                      </Space>
                    ),
                  rowExpandable: (record) => record.actions.length,
                  expandIcon: ({ expanded, onExpand, record }) =>
                    record.actions.length ? (
                      expanded ? (
                        <MinusCircleOutlined
                          color={token.colorPrimary}
                          onClick={(e) => onExpand(record, e)}
                        />
                      ) : (
                        <PlusCircleOutlined
                          color={token.colorPrimary}
                          onClick={(e) => onExpand(record, e)}
                        />
                      )
                    ) : (
                      <></>
                    ),
                  expandRowByClick: true,
                }}
              />
              <Pagination
                style={{ float: "right" }}
                current={currentPage}
                onChange={onPageChange}
                pageSize={PAGE_SIZE}
                total={resultMeta.count}
                showSizeChanger={false}
                hideOnSinglePage={true}
              />
            </Space>
          </div>
        )}
      </Panel>
    </Collapse>
  );
};

export default ResultPanel;
