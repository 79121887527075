import { FC, useMemo, useEffect } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

import { SearchInputProps } from "types";

const SearchInput: FC<SearchInputProps> = (props) => {
  const debouncedResults = useMemo(() => {
    return debounce(props.onFilter, 500);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  return (
    <Input
      placeholder={props.placeholder}
      defaultValue={props.value ? props.value : undefined}
      suffix={<SearchOutlined />}
      onChange={debouncedResults}
      style={{
        width: "auto",
        display: "flex",
        justifyContent: "center",
      }}
    />
  );
};

export default SearchInput;
