import { FC, useEffect, useState } from "react";
import {
  Button,
  Card,
  Empty,
  Flex,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
  notification,
  theme,
} from "antd";
import {
  EditOutlined,
} from "@ant-design/icons";

import { useOrganizationStore } from "store";

import { EntitiesDisplay } from "components/EntitiesDisplay";
import {
  Edge,
  EdgeManageType,
  IODataType,
  ParameterStoreParameter,
  ParameterStoreEdgePolicyName,
  Policy,
  PolicyCategoryType,
} from "types";
import { ColumnsType } from "antd/es/table";
import { HLink } from "components/Link";
import ParameterStoreItem  from "./item";
import { usePolicyStore } from "store/policy";
import { SvgIcon } from "components/SvgIcon";
import { getEdgeShortLogo } from "utility/edge";
import { useParameterStoreStore } from "store/parameter_store";
import EdgeSelection from "components/EdgeSelection";
import { getEdgeApi } from "api";
import { getTagColor } from "utility";

const { Text } = Typography;

const ParameterStore: FC = () => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;
  const [policyLoaded, setPolicyLoaded] = useState<boolean>(false);
  const [showEdges, setShowEdges] = useState<boolean>(false);
  const [selectedEdge, setSelectedEdge] = useState<Edge|undefined>();
  const [selectedParameter, setSelectedParameter] = useState<ParameterStoreParameter | null>(null);
  const [parameterStorePolicy, setParameterStorePolicy] = useState<Policy|undefined>();

  const context = useOrganizationStore((state) => state.context);

  const {
    getPolicy,
    updatePolicy,
  } = usePolicyStore((state) => ({
    getPolicy: state.getPolicy,
    updatePolicy: state.updatePolicy,
  }));

  const {
    parameters,
    getParameters,
    createParameter,
    updateParameter,
    deleteParameter,
  } = useParameterStoreStore((state) => ({
    parameters: state.parameters,
    getParameters: state.getParameters,
    createParameter: state.createParameter,
    updateParameter: state.updateParameter,
    deleteParameter: state.deleteParameter,
  }));
  
  const loadParamStorePolicy = async () => {
    try {
      setLoader(true);
      const policy = await getPolicy(PolicyCategoryType.WorkflowGeneral, ParameterStoreEdgePolicyName, true);
      if (policy) {
        setParameterStorePolicy(policy);
        if (policy?.value.data && policy?.value.data != "") {
          const edgeId = policy?.value.data;
          const edge = await getEdgeApi(edgeId);
          setSelectedEdge(edge);
        }
      } else {
        setSelectedEdge(undefined);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
      setPolicyLoaded(true);
    }
  };

  useEffect(() => {
    loadParamStorePolicy()
  }, [context]);

  const loadParameters = async () => {
    try {
      setLoader(true);
      if (selectedEdge?.id && parameterStorePolicy?.id) {
        await getParameters(selectedEdge?.id, parameterStorePolicy?.id, currentPage, pageSize);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadParameters();
  }, [selectedEdge]);

  const updateParamStorePolicy = async (edgeId: string) => {
    try {
      setLoader(true);
      if (parameterStorePolicy) {
        const policyValue = parameterStorePolicy?.value;
        policyValue.data = edgeId;
        const newPolicy = {
          id: parameterStorePolicy.id,
          value: policyValue
        } as Policy;
        await updatePolicy(PolicyCategoryType.WorkflowGeneral, newPolicy);
        notification.success({
          message: `Parameter store edge policy updated successfully`,
          duration: 6,
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while updating policy...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  }

  const onParameterCreate = async (parameter: ParameterStoreParameter) => {
    try {
      setLoader(true);
      if (selectedEdge?.id && parameterStorePolicy?.id) {
        await createParameter(selectedEdge?.id, parameterStorePolicy?.id, parameter);
        notification.success({
          message: `Parameter created successfully`,
          duration: 6,
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while creating parameter...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const onParameterUpdate = async (name: string, parameter: ParameterStoreParameter) => {
    try {
      setLoader(true);
      if (selectedEdge?.id && parameterStorePolicy?.id) {
        await updateParameter(selectedEdge?.id, parameterStorePolicy?.id, name, parameter);
        notification.success({
          message: `Parameter updated successfully`,
          duration: 6,
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while updating parameter...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const onParametersDelete = async (parameters: ParameterStoreParameter[]) => {
    try {
      setLoader(true);
      if (selectedEdge?.id && parameterStorePolicy?.id) {
        await Promise.all(
          parameters.map(async (parameter) => {
            await deleteParameter(selectedEdge?.id, parameterStorePolicy?.id, parameter.name);
            notification.success({
              message: `Parameter ${parameter.name} deleted successfully`,
              duration: 6,
            });
          })
        );
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while deleting parameters...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const columns: ColumnsType<ParameterStoreParameter> = [
    {
      title: "Name",
      dataIndex: "",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      render: (p: ParameterStoreParameter) => (
        <HLink
          onClick={() => {
            setSelectedParameter(p);
          }}
          text={p.name}
          tooltip={p.description}
        />
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      render: (type) => (
        <Text>{type == IODataType.String ? "String" : "StringList"}</Text>
      ),
    },
    {
      title: "Value",
      dataIndex: "",
      key: "value",
      render: (p: ParameterStoreParameter) => (
        <Text>{p.type == IODataType.String ? p.value : p.value.join(",")}</Text>
      ),
    },
  ]

  return (
    <Spin spinning={loader}>
      {selectedParameter && (
        <ParameterStoreItem 
          editMode={true}
          item={selectedParameter}
          onSubmit={async (values) => {
            setSelectedParameter(null);
            (selectedParameter.name != "") ? onParameterUpdate(selectedParameter.name, values as ParameterStoreParameter)
                                    : onParameterCreate(values as ParameterStoreParameter);
          }}
          onClose={() => setSelectedParameter(null)}
          checkNameExists={(name) => {
            if (selectedParameter.name == "") {
              const parameter = parameters.find((p) => p.name == name);
              return parameter != undefined;
            }
            return false;
          }}
        />
      )}
      {showEdges 
        &&
        <EdgeSelection 
          width={600}
          open={showEdges}
          onClose={() => setShowEdges(false)}
          selectedEdge={selectedEdge}
          onSelect={(edge) => {
            updateParamStorePolicy(edge.id);
            setSelectedEdge(edge);
          }}
        />
      }
      {selectedEdge 
        ? 
          <>
            <Card 
              size="small"
              style={{ 
                borderRadius: token.borderRadius, 
                borderColor: token.colorPrimaryBorder,
                background: token.colorPrimaryBg,
              }}
            >
              <Flex gap={"middle"} justify="space-between">
                <Space size={token.size}>
                  <SvgIcon size={25} component={getEdgeShortLogo(selectedEdge.infraType)} />
                  <Tooltip
                    color={token.colorPrimaryHover}
                    title={selectedEdge.description}
                    placement={"top"}
                  >
                    <Text>{selectedEdge.displayName}</Text>
                  </Tooltip>
                  <Tag color={getTagColor(selectedEdge.region)}>
                    {selectedEdge.region}
                  </Tag>
                  <Tag color={getTagColor(selectedEdge.manageType)} >
                    {selectedEdge.manageType == EdgeManageType.HyprEdge? "HyprEdge Managed" : "Customer Managed"}
                  </Tag>
                </Space>
                {/* TODO - enable this when current parameter store parameters should be able to migrate to edge selected by user
                or provide warnign to user to loss the current parameters when switch edge from current */}
                {/* <Button
                  type="link"
                  ghost
                  icon={<EditOutlined />}
                  onClick={() => setShowEdges(true)}
                /> */}
              </Flex>
            </Card>
            <EntitiesDisplay
              header={"Parameters"}
              dataSource={parameters}
              columns={columns}
              pageNumber={currentPage}
              pageSize={pageSize}
              total={parameters?.length}
              rowKey={"name"}
              onPageChange={(page) => {
                setCurrentPage(page);
              }}
              getItemProps={() => {
                return {
                  disabled: false,
                };
              }}
              actions={
                [
                  {
                    key: "refresh", 
                    label: "Refresh", 
                    enable: () => true, 
                    onClick: () => {loadParameters()}
                  },
                  {
                    key: "add", 
                    label: "Add", 
                    enable: () => true, 
                    onClick: () => {setSelectedParameter({name: "", description: "", type: IODataType.String, value: ""} as ParameterStoreParameter)}
                  },
                  {
                    key: "delete", 
                    label: "Delete", 
                    enable: (itemIds) => {
                      return (itemIds && itemIds.length) ? true : false;
                    }, 
                    showWarn: true,
                    warnMessage: <div>Are you sure to delete parameters? <br></br> Dependent workflows or searches will be impacted</div>,
                    onClick: (value) => {
                      const names = value as string[];
                      const items = parameters.filter((p) => names.find((name) => name == p.name))
                      if (items) {
                        onParametersDelete(items);
                      }
                    }
                  }
                ]
              }
            />
          </>
        :
          policyLoaded
          ? 
            <Empty description={"Create Parameter Store at Edge"}>
              <Button type="primary" onClick={() => setShowEdges(true)}>Create</Button>
            </Empty>   
          :
            <></>
      }
    </Spin>
  );
};

export default ParameterStore;
