

export enum GoalMeasurement {
  NUMBER = 'NUMBER',
  PERCENTAGE = 'PERCENTAGE',
  CURRENCY = 'CURRENCY',
  TIME = 'TIME',
  BOOLEAN = 'BOOLEAN',
  OTHER = 'OTHER',
}
export interface Goal {
  id: string;
  title: string;
  description: string;
  target: string;
  measurement: GoalMeasurement;
  enabled: boolean;
  search: string;
  owner: string;
  members: string[];
  workflows: string[];
  metric: boolean;  
}


export type GoalCreateRequest = Omit<Goal, "id">;
export type GoalUpdateRequest = Partial<GoalCreateRequest>;
export type GoalList = { goals : Goal[]};

export interface GoalStore {
  loading: boolean;
  goals: Goal[]; 
  goalMetrics: Record<string, number[]>;
  setLoading: (loading: boolean) => Promise<void>;
  listGoals: () => Promise<void>; 
  addGoal: (goal: GoalCreateRequest) => Promise<void>;
  updateGoal: (id: string , goal: GoalUpdateRequest) => Promise<void>;
  deleteGoal: (id: string) => Promise<void>;
}
