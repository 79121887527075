import { FC, useEffect, useState } from "react";
import { theme, Spin, Flex, Typography, List, Button, Badge } from "antd";
import {
  ReloadOutlined,
} from '@ant-design/icons';

import VirtualList from "rc-virtual-list";

import { Edge, EdgeInfraTypeMap, EdgeManageTypeMap, EdgeStatus } from "types";
import SearchInput from "components/SearchInput";
import EdgeInfo from "./edgeInfo";
import { FiltersAndSorts } from "components/FiltersAndSorters";
import { useEdgeGlobalStore } from "store";

const { Text } = Typography;

export interface EdgeInfoProps {
  selectedEdge?: Edge;
  onSelect: (edge: Edge) => void;
  enableAutoDetect?: boolean;
  onAutoDetect?: () => void;
  recommendedEdges?: string[];
}

const EdgeInfos: FC<EdgeInfoProps> = ({
  selectedEdge,
  onSelect,
  enableAutoDetect,
  onAutoDetect,
  recommendedEdges
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [filteredEdges, setFilteredEdges] = useState<Edge[]>([]);
  const [searchText, setSearchText] = useState("");
  const [edgefilters, setEdgeFilters] = useState<Record<string, string[]>>({});

  const {
    edges,
    getEdges,
   } = useEdgeGlobalStore((state) => ({
    edges: state.edges,
    getEdges: state.getEdges,
  }));

  useEffect(() => {
    let filteredEdges = edges.sort((a, b) => a.status == EdgeStatus.Online ? -1 : 1);
    filteredEdges = filteredEdges.filter((e) => e.displayName.indexOf(searchText) !== -1);
    Object.entries(edgefilters).map(([name, values]) => {
      if (name == 'infraType') {
        filteredEdges = filteredEdges.filter((e) => values.includes(e.infraType));
      }
      if (name == 'manageType') {
        filteredEdges = filteredEdges.filter((e) => values.includes(e.manageType));
      }
    });
    setFilteredEdges([...filteredEdges]);
  }, [edges, searchText, edgefilters]);

  const loadEdges = async () => {
    try {
      setLoader(true); 
      getEdges();
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <Flex gap={"small"} style={{ marginBottom: token.margin }} justify="space-between">
        <div style={{ width: "100%" }}>
          <SearchInput
            placeholder={"Search edges by name..."}
            onFilter={(e) => setSearchText(e.target.value)}
          />
        </div>
        <FiltersAndSorts
          //title={"Filters"}
          currentFilters={edgefilters}
          filters={[
            {
              name: "infraType",
              displayName: "Infra Type",  
              options: EdgeInfraTypeMap,
            },
            {
              name: "manageType",
              displayName: "Owned By",  
              options: EdgeManageTypeMap,
            },
          ]}
          onApply={(filters) => {
            filters && setEdgeFilters(filters);
          }}
        />
        <Button
          type="primary"
          icon={<ReloadOutlined />}
          onClick={() => {
            loadEdges();
          }}
        />
        {enableAutoDetect
          &&
          <Button
            type="primary"
            onClick={() => onAutoDetect?.()}
          >
            Auto Detect
          </Button>
        }
      </Flex>
      <Spin spinning={loader}>
      {edges.length > 0 && (
        <VirtualList
          data={filteredEdges}
          height={500}
          itemKey="id"
          //onScroll={onScroll}
        >
          {(edge: Edge) => (         
            <List.Item key={edge.id}>
              {recommendedEdges?.includes(edge.id)
                ?
                  <Badge.Ribbon  key={edge.id} text="Recommended" color="magenta">
                    <EdgeInfo
                      key={edge.id}
                      edge={edge}
                      editMode={true}
                      isSelectedEdge={selectedEdge != undefined && edge.id == selectedEdge.id}
                      onClick={(edge.status == EdgeStatus.Online) ? () => onSelect(edge) : undefined}
                    />
                  </Badge.Ribbon>
                :
                  <EdgeInfo
                    key={edge.id}
                    edge={edge}
                    editMode={true}
                    isSelectedEdge={selectedEdge != undefined && edge.id == selectedEdge.id}
                    onClick={(edge.status == EdgeStatus.Online) ? () => onSelect(edge) : undefined}
                  />
              }
            </List.Item>
          )}
        </VirtualList>
      )}
      </Spin>
    </>
  );
};

export default EdgeInfos;
